<template>
  <vx-card>
    <div>
      <div class="vx-col mb-6 w-1/2"></div>
      <vs-table stripe border description :sst="true" :data="data">
        <!-- <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{ this.table.start }} - {{ this.table.end }} of
                  {{ this.table.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in table.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template> -->
        <template slot="thead">
          <vs-th sort-key="number">Vehicle Number</vs-th>
          <vs-th sort-key="zone_name">Zone Operation</vs-th>
          <vs-th sort-key="name">Driver Name</vs-th>
          <vs-th sort-key="name">Availability</vs-th>
          <vs-th sort-key="name"># Of DO</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].number">
              {{ data[indextr].number }}
            </vs-td>
            <vs-td :data="data[indextr].zone_name">
              {{ data[indextr].zone_name }}
            </vs-td>
            <vs-td>
              <div class="vx-col sm:w-4/5 w-full">
                <vs-input
                  v-model="data[indextr].driver_name"
                  type="text"
                  disabled
                />
              </div>
            </vs-td>
            <vs-td>
              <div class="center con-switch">
                <vs-switch v-model="data[indextr].availability" disabled>
                </vs-switch>
              </div>
            </vs-td>
            <vs-td>
              <div class="center con-switch">
                <vs-input
                  v-model="data[indextr].total_do"
                  type="number"
                  disabled
                />
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <!-- <vs-pagination
          style="padding-top: 5px"
          :total="table.totalPage"
          v-model="setPage"
        /> -->
      <!-- <div class="vx-row flex justify-between mt-5 m-10">
        <div>
          <vs-button class="mb-2" v-on:click="SubmitForm()">Save</vs-button>
        </div>
        <div>
          <vs-button class="mb-2" v-on:click="handleClose()">Close</vs-button>
        </div>
      </div> -->
    </div>
  </vx-card>
</template>

<script>
import moment from "moment";
export default {
  components: {},
  props: {
    filterActive: {
      type: Boolean,
    },
    selected: {
      type: Object,
    },
    data: {
      type: Array,
    },
    optionalDrivers: {
      type: Array,
    },
  },

  mounted() {
    // this.getDriver();
  },

  data() {
    return {};
  },
  methods: {
    customLabelDriver({ Personal }) {
      return `${Personal.name}`;
    },
  },
  watch: {},
  computed: {},
  filters: {},
};
</script>

<style>
.vs-table--tbody-table
  .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover {
  -webkit-transform: none;
  transform: none;
}
</style>
